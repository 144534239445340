import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { Box, styled, Stack, Typography } from '@mui/material'
import { ImageFlux } from 'components/elements/ImageFlux'
import { notFound as t } from 'public/locales/ja/pages/404'

const NotFound: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) {
    return <div />
  }

  return (
    <Container>
      <Stack pt={3} justifyContent={'center'} alignItems={'center'}>
        <LogoWrapper>
          <ImageFlux src={'/images/logo_manavi_white.png'} width={'90'} height={'24'} alt="logo" />
        </LogoWrapper>
      </Stack>
      <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
        <TextStyled pb={2}>{t.title}</TextStyled>
        <ImageContainerStyled>
          <ImageFlux src={'/images/404.png'} width={'200'} alt="404-error" />
        </ImageContainerStyled>
        <Box pt={2} textAlign={'center'}>
          <TextStyled>{t.aboutSeller}</TextStyled>
          <Link href={'/about/seller'} passHref>
            <LinkStyled>{'https://ma-navigator.com/about/seller'}</LinkStyled>
          </Link>
        </Box>
        <Box pt={2} textAlign={'center'}>
          <TextStyled>{t.projects}</TextStyled>
          <Link href={'/projects'} passHref>
            <LinkStyled>{'https://ma-navigator.com/projects'}</LinkStyled>
          </Link>
        </Box>
      </Stack>
    </Container>
  )
}
export default NotFound

const Container = styled(Stack)(
  ({ theme }) => `
      height: 100vh;
      width: 100vw;
      background-color: ${theme.primary.base};
`
)
const TextStyled = styled(Typography)(
  ({ theme }) => `
      font-weight: 700;
      font-size: ${theme.typography.pxToRem(20)};
      color: ${theme.white.base};
`
)
const LinkStyled = styled(Typography)(
  ({ theme }) => `
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(16)};
      color: ${theme.yellow.base};
`
)
const LogoWrapper = styled(Box)(
  () => `
      height: 24px;
      width: 90px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
`
)
const ImageContainerStyled = styled(Box)(
  () => `
      height: auto;
      width: 200px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
`
)
